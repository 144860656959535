import { Injectable } from '@angular/core';
import { map, Observable, switchMap, tap } from 'rxjs';
import { ApiService } from '../api/api.service';
import { AppStateService } from '../app-state/app-state.service';
import { Dictionary } from '@resparke/models';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  constructor(
    private apiService: ApiService,
    private appState: AppStateService,
    private authService: AuthService,
  ) { }

  getContent(items: string[]): Observable<boolean> {
    const data = {
      items
    };
    const statement = `
      query getContentItems($input: ContentItemsInput!) {
        getContentItems(input: $input) {
          name
          content
        }
      }
    `;
    return this.authService.isLoggedIn()
      .pipe(
        switchMap(authState => {
          const useIam = authState ? !authState.isLoggedIn : true;
          return this.apiService
            .graphql<ContentItem[]>({ statement, variables: { input: data }, type: 'getContentItems', iam: useIam })
            .pipe(
              tap(items => {
                const entries: Dictionary<any> = {};
                items.forEach(item => {
                  const [key, name] = item.name.split(':');
                  if (!entries[key]) {
                    entries[key] = {};
                  }
                  entries[key][name] = item.content
                });
                const currentEntries = this.appState.get<ContentItemCollection>('contentItemCollection') || {};
                for (const [key, entry] of Object.entries(entries)) {
                  if (currentEntries[key]) {
                    currentEntries[key] = {
                      ...currentEntries[key],
                      ...entry
                    }
                  } else {
                    currentEntries[key] = entry;
                  }
                }
                this.appState.setState('contentItemCollection', currentEntries);

              }),
              map(val => true),
            )
        })
      )
  }
}

export interface ContentItemCollection {
  [key: string]: {
    [key: string]: string;
  }
}

export interface ContentItem {
  name: string;
  content: string;
}